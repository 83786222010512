$theme-colors: (
  "primary": #333,
  "success": #52dba1,
);

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";

h1, h2, h3, h4 {
  font-weight: 600 !important;
}

.jumbotron--branded {
  color: #ffffff;
  background-color: #222;
}

.jumbotron--branded .text-muted {
  color: #ececec !important;
}

.btn i {
  margin-right: 0.5em;
}

.modal-title {
  font-size: 1.2em;
}

.mt-nav {
  margin-top: 4.5em;
}