.Page {
}

.Page__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.navbar.bg-dark {
  background-color: #111 !important;
}

.navbar-dark .navbar-text .dropdown-item,
.navbar-dark .navbar-text .dropdown-item:hover {
  color: #000;
}

.navbar-dark .navbar-text .dropdown-item:focus {
  color: #fff;
}

.breadcrumb {
  background-color: #3c3c3c !important;
  border-radius: 0 !important;
}

.breadcrumb-item.active {
  color: rgba(#fff, 0.5) !important;
}

.breadcrumb-item a {
  color: #fff !important;
}

.navbar-brand img {
  max-height: 3rem;
}