.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: opacity 150ms ease-out;
  z-index: 10;

  &__image {
    height: 50px;
    width: 50px;
  }

  &__message {
    font-weight: 600;
    margin-top: 1rem;
  }

  &-exit {
    opacity: 0.5;
  }

  &-exit-done {
    opacity: 0;
  }
}
